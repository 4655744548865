import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Delicious & Nutrient Rich <HighlightedText>Meal.</HighlightedText></>}
        description="Discover the perfect blend of nutrition and flavor with our carefully crafted meal bowls. Each bowl is packed with wholesome ingredients like fresh veggies, protein-rich beans, and savory curries, all designed to provide balanced nutrients in every bite. Eating well has never been this easy or enjoyable."
        imageSrc="https://img.freepik.com/premium-photo/balanced-buddha-bowl-with-grilled-chicken-spicy-chickpeas_875825-71303.jpg?w=740"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />

      <MainFeature
        subheading={<Subheading>Serving with Care</Subheading>}
        heading={
          <>
            We've been serving 
            <wbr /> <HighlightedText>with care</HighlightedText>
          </>
        }
        description={
          <Description>
            We believe that food should be more than just fuel—it should be an experience. That’s why we’re committed to creating meals that not only nourish your body but also delight your senses.
            <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={
          "https://www.southernliving.com/thmb/2pGdniJp4gdDUny-9r3qZALsX9s=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Burrito_Bowl_006-6d8dab84db36463da1d4ce6543b70fc4.jpg"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>menu.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
          <HighlightedText>Real Ingredients, Real Nutrition</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Wholesome Ingredients",
            description: "We use only the best, avoiding palm oil, deep-fried oils, and harmful packaging. Our meals are made in a clean, hygienic environment with a focus on purity and sustainability.",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Deliciously Indian",
            description: "Our bowls feature a modern twist on traditional Indian flavors, bringing together the rich heritage of Indian cuisine with contemporary nutritional science",
          },

          {
            imageSrc: celebrationIconImageSrc,
            title: "Side Effects",
            description: "Our Balanced, Delicious, Nutrient rich meal can cause a side effect of being healthy (Pun Intended)",
            // url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        description={<>Our bowls are meticulously crafted to provide a well-rounded meal that meets your daily nutritional needs. Each bowl is packed with</>}
        statistics={[
          {
            key: "Protein",
            value: "20-42g",
          },
          {
            key: "Fiber",
            value: "7-12g"
          },
          {
            key: "Carbs",
            value: "<100g"
          }
          
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
        testimonials = {[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote:
            "These bowls are a game-changer! I love that I can enjoy traditional Indian flavors without compromising on my diet.",
            customerName: "Surya"
          },

          {
            imageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote: 
            "Every meal feels like it’s made just for me—balanced, delicious, and exactly what I need to stay on track.",
            customerName: "Harika"
          },

          {
            imageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            quote:
            "I appreciate the focus on sustainability and health. It’s rare to find food that’s both good for you and the planet.",
            customerName: "Manoj"
          },
        
        ]
        }
      />
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
}
