import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Balanc'd</Subheading>}
        heading="Nourishing your body with balanced meals."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        description="At Balancd, we believe that healthy eating doesn’t have to be boring or complicated. Our mission is to create nutrient-rich, delicious, and convenient meals that nourish your body and satisfy your taste buds. We focus on balancing essential nutrients, using wholesome ingredients and traditional Indian flavors to craft meals that are both flavorful and good for you."
        imageSrc="https://img.freepik.com/free-photo/life-style_1122-1996.jpg?t=st=1725348970~exp=1725352570~hmac=247cae5eb4e97d2c7b531aad86af616318093b0a663a29a2f281e888e2710a99&w=740"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Making healthy eating simple & delicious."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        description="Our vision is to make balanced eating accessible to everyone, everywhere. We aim to revolutionize the way people think about healthy food by offering meals that are not only nutritious but also exciting and full of flavor. Whether you’re at home, at work, or on the go, Balancd is here to support your journey towards a healthier lifestyle with meals that you can feel good about."
        imageSrc="https://img.freepik.com/free-photo/people-enjoying-mexican-barbecue_23-2151000322.jpg?t=st=1725348557~exp=1725352157~hmac=2e61d60971551516fa1af7aa824f5728fb2ce321383f43fb687a23c824d99ed8&w=740"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        description="Quality, nutrition, and sustainability in every bite"
        heading="We follow these."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Quality Ingredients",
            description: "We use only the freshest, highest-quality ingredients in our meals, avoiding artificial additives, preservatives, and unhealthy fats. Each bowl is crafted with care in a clean, hygienic environment."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Nutrition & flavour",
            description: "We believe that nutritious food should also be delicious. Our meals are thoughtfully designed to deliver balanced nutrition without compromising on taste, blending traditional Indian flavors with modern dietary needs."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Sustainability",
            description: "We are committed to sustainability in every aspect of our business, from sourcing ingredients responsibly to using eco-friendly packaging. Our goal is to create meals that are good for you and good for the planet"
          },
        ]}
        linkText=""
      />
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
